<script>
import { FormWizard, TabContent } from "vue-form-wizard";

import moment from "moment";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { apiRequest } from "@/helpers/api-call";
import NumberInputSpinner from "vue-number-input-spinner";
import { Client } from "@googlemaps/google-maps-services-js";
const api_key = "AIzaSyDlf2gLsEz37Lsukm_tg26ew5H7QViHSpo";
const client = new Client({});
//import axios from "axios";

export default {
  page: {
    title: "Nouveau",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    Multiselect,
    NumberInputSpinner,
  },
  data() {
    return {
      title: "Nouveau",
      items: [
        {
          text: "Trajet",
          href: "/",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],
      value: null,
      users: [],
      valueEscales: [],
      suggestEscales: [],
      nmbrePlace: 1,
      price: 1,
      priceget: {
        prix: 0,
        prixMin: 0,
        prixJaune: 0,
        prixRouge: 0,
      },
      dateDepartValue: null,
      dateDepart: new Date().toLocaleString("fr-FR"),
      dateArrivee: new Date().toLocaleString("fr-FR"),
      escales: {
        nom: "",
        latitude: 0,
        longitude: 0,
      },
      trajet: {
        user: 0,
        type: "",
        nature: "",
        lieuDepart: {
          nom: "",
          latitude: 0,
          longitude: 0,
        },
        pointDeRencontre: {
          nom: "",
          latitude: 0,
          longitude: 0,
        },
        lieuArrivee: {
          nom: "",
          latitude: 0,
          longitude: 0,
        },
        pointDeRencontreArrivee: {
          nom: "",
          latitude: 0,
          longitude: 0,
        },
      },
      distanceKM: 0,
      typeUser: 1,
      flexibilite: 5,
      bagage: ["Petit", "Moyen", "Grand"],
      bagageValue: "Petit",
      mode: ["AUTO", "MANUEL"],
      modeValue: "AUTO",
      infos: "",
      notif: "",
    };
  },
  async mounted() {
    //Chargement des users
    const data = await apiRequest("GET", "admin/users", undefined, false);
    if (data && data.data) {
      const formattedTable = data.data.map((user) => {
        return {
          id: user.id,
          name: user.lastname[0] + ". " + user.firstname,
          type: user.userType,
        };
      });
      this.users = formattedTable;
    }
  },
  methods: {
    setDepart: function (place) {
      if (place) {
        this.trajet.lieuDepart.nom = place.formatted_address;
        this.trajet.lieuDepart.latitude = place.geometry.location.lat();
        this.trajet.lieuDepart.longitude = place.geometry.location.lng();
        this.suggestionEscales();
      }
    },
    setRencontreDepart: function (place) {
      if (place) {
        this.trajet.pointDeRencontre.nom = place.formatted_address;
        this.trajet.pointDeRencontre.latitude = place.geometry.location.lat();
        this.trajet.pointDeRencontre.longitude = place.geometry.location.lng();
      }
    },
    setArrive: function (place) {
      if (place) {
        this.trajet.lieuArrivee.nom = place.formatted_address;
        this.trajet.lieuArrivee.latitude = place.geometry.location.lat();
        this.trajet.lieuArrivee.longitude = place.geometry.location.lng();
        this.suggestionEscales();
      }
    },
    setRencontreArrive: function (place) {
      if (place) {
        this.trajet.pointDeRencontreArrivee.nom = place.formatted_address;
        this.trajet.pointDeRencontreArrivee.latitude =
          place.geometry.location.lat();
        this.trajet.pointDeRencontreArrivee.longitude =
          place.geometry.location.lng();
      }
    },
    async suggestionEscales() {
      if (
        this.trajet.lieuDepart.nom != "" &&
        this.trajet.lieuDepart.latitude != 0 &&
        this.trajet.lieuDepart.longitude != 0 &&
        this.trajet.lieuArrivee.nom != "" &&
        this.trajet.lieuArrivee.latitude != 0 &&
        this.trajet.lieuArrivee.longitude != 0
      ) {
        const escales = await apiRequest(
          "POST",
          "suggest-escales",
          {
            lieuDepart: this.trajet.lieuDepart,
            lieuArrivee: this.trajet.lieuArrivee,
          },
          false
        );
        if (escales && escales.data) {
          this.suggestEscales = escales.data.escales;
        }
        this.getTimeAndDistance(
          this.trajet.lieuDepart.nom,
          this.trajet.lieuArrivee.nom
        );
      }
    },
    setEscales: function (place) {
      if (place) {
        this.escales.nom = place.formatted_address;
        this.escales.latitude = place.geometry.location.lat();
        this.escales.longitude = place.geometry.location.lng();

        this.valueEscales.push(this.escales);

        this.escales.nom = "";
        this.escales.latitude = 0;
        this.escales.longitude = 0;
      }
    },
    async getTimeAndDistance(origin, destination) {
      const waypts = [];
      try {
        var all = await Promise.all([
          client.directions({
            params: {
              origin: origin,
              destination: destination,
              travelMode: "DRIVING",
              waypoints: waypts,
              optimizeWaypoints: true,
              key: api_key,
              units: "metric",
            },
          }),
        ]);
      } catch (error) {
        
       console.log(error);
      }

      if (all && all.length > 0 && all[0].data) {
        var distanceEnM = all[0].data.routes[0].legs[0].distance.value;
        var duree = all[0].data.routes[0].legs[0].duration.value;

        this.dateDepart = new Date(this.dateDepartValue);
        this.dateArrivee = moment(this.dateDepart).add(duree, "s").toDate(); //new Date(new Date().setSeconds(this.dateDepart.getSeconds() + duree));

        this.distanceKM = distanceEnM / 1000;

        if (this.value.type == "CONDUCTEUR") {
          //MOTO
          this.typeUser = 2;
        } else if (this.value.type == "PARTICULIER") {
          //VOITURE
          this.typeUser = 1;
        } else if (this.value.type == "TAXIMAN") {
          //TAXIMAN
          this.typeUser = 3;
        } else if (this.value.type == "SOCIETE") {
          //SOCIETE
          this.typeUser = 4;
        }

        if (this.typeUser != 0 && this.distanceKM != 0) {
          this.getPrice(this.distanceKM, this.typeUser);
        }
      }

      //var town = new Array();
    },
    async getPrice(distanceTrajet, type) {
      const prices = await apiRequest(
        "POST",
        "prix",
        {
          distance: parseFloat(distanceTrajet),
          moyensTransport: type,
        },
        false
      );
      if (prices && prices.data) {
        this.priceget = prices.data;
        this.price = prices.data.prix;
        
      }
    },
    onComplete: function () {
      alert("Yay. Done!");
    },
    async saveTrajet() {
      var moyen = "";
      if (this.value.type == "CONDUCTEUR") {
        moyen = "MOTO";
      } else if (this.value.type == "PARTICULIER") {
        moyen = "VOITURE";
      } else if (this.value.type == "TAXIMAN") {
        moyen = "TAXI";
      } else if (this.value.type == "SOCIETE") {
        moyen = "BUS";
      }

      const trajet = await apiRequest(
        "POST",
        "admin/trajet",
        {
          idUser: this.trajet.user,
          typeTrajet: this.trajet.type,
          natureTrajet: this.trajet.nature,
          moyenTransport: moyen,
          dateHeureDepart: this.dateDepart,
          dateHeureArrive: this.dateArrivee,
          lieuDepart: this.trajet.lieuDepart,
          lieuArrivee: this.trajet.lieuArrivee,
          pointDeRencontre: this.trajet.pointDeRencontre,
          pointDeDescentePrevu: this.trajet.pointDeRencontreArrivee,
          prixPublie: this.price,
          distance: this.distanceKM,
          nombrePlace: this.nmbrePlace,
          infosSup: this.infos,
          tailleBagageAutorise: this.bagageValue,
          flexibilite: this.flexibilite,
          modeReservation: this.modeValue,
          escales: this.valueEscales,
        },
        false
      );
      if (trajet) {
        if (trajet.data) {
      

          this.$toast.success("Trajet enregistré avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
        } else {
      
          this.$toast.error("Une erreur est survenu", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
        }
      }
    },
  },
  computed: {
    notification() {
      return this.notif != "" ? this.notif : null;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Nouveau</h4>

            <div class="card">
              <div class="card-body">
                <form-wizard color="#5664d2" @on-complete="onComplete">
                  <tab-content title="" aria-hidden="">
                    <div class="tab-pane" id="payment-info">
                      <!--<h5 class="card-title">Payment information</h5>
                      <p class="card-title-desc">
                        It will be as simple as occidental in fact
                      </p>-->
                      <div>
                        <div class="form-group">
                          <label class="control-label"
                            >Choisir l'utilisateur :</label
                          >
                          <multiselect
                            v-model="value"
                            :options="users"
                            label="name"
                            track-by="name"
                          ></multiselect>
                        </div>

                        <h5 class="font-size-14">Type de trajet :</h5>

                        <div class="row">
                          <div class="col-lg-4 col-sm-6">
                            <div>
                              <label class="card-radio-label mb-3">
                                <input
                                  type="radio"
                                  class="card-radio-input"
                                  name="type"
                                  value="PONCTUEL"
                                  v-model="trajet.type"
                                />

                                <div class="card-radio">
                                  <i
                                    class="fa fa-calendar-day font-size-24 align-middle mr-2"
                                  ></i>
                                  <span>Trajet Ponctuel</span>
                                </div>
                              </label>
                            </div>
                          </div>

                          <div class="col-lg-4 col-sm-6">
                            <div>
                              <label class="card-radio-label mb-3">
                                <input
                                  type="radio"
                                  class="card-radio-input"
                                  value="REGULIER"
                                  name="type"
                                  v-model="trajet.type"
                                />

                                <div class="card-radio">
                                  <i
                                    class="fa fa-calendar-week font-size-24 align-middle mr-2"
                                  ></i>
                                  <span>Trajet Régulier</span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>

                        <h5 class="font-size-14">Nature de trajet :</h5>

                        <div class="row">
                          <div class="col-lg-4 col-sm-6">
                            <div>
                              <label class="card-radio-label mb-3">
                                <input
                                  type="radio"
                                  class="card-radio-input"
                                  name="nature"
                                  value="ALLERSIMPLE"
                                  v-model="trajet.nature"
                                />
                                <div class="card-radio">
                                  <i
                                    class="fa fa-map-marker font-size-24 align-middle mr-2"
                                  ></i>
                                  <span>Aller Simple</span>
                                </div>
                              </label>
                            </div>
                          </div>

                          <div class="col-lg-4 col-sm-6">
                            <div>
                              <label class="card-radio-label mb-3">
                                <input
                                  type="radio"
                                  class="card-radio-input"
                                  name="nature"
                                  value="ALLERRETOUR"
                                  v-model="trajet.nature"
                                />

                                <div class="card-radio">
                                  <i
                                    class="fa fa-map-marked font-size-24 align-middle mr-2"
                                  ></i>
                                  <span>Aller Retour</span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-content>

                  <tab-content title="">
                    <div class="tab-pane" id="billing-info">
                      <form>
                        <div>
                          <div>
                            <div class="row">
                              <div class="col-lg-3">
                                <div class="form-group mb-4">
                                  <label for="depart">Point de départ</label>
                                  <gmap-autocomplete
                                    type="text"
                                    class="form-control"
                                    id="depart"
                                    placeholder="Point de départ"
                                    @place_changed="setDepart"
                                    :select-first-on-enter="true"
                                  >
                                  </gmap-autocomplete>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group mb-4">
                                  <label for="rencontre"
                                    >Lieu de Rencontre</label
                                  >
                                  <gmap-autocomplete
                                    type="text"
                                    class="form-control"
                                    id="depart"
                                    placeholder="Lieu de Rencontre"
                                    @place_changed="setRencontreDepart"
                                    :select-first-on-enter="true"
                                  >
                                  </gmap-autocomplete>
                                </div>
                              </div>

                              <div class="col-lg-3">
                                <div class="form-group mb-4">
                                  <label for="rencontre"
                                    >Quand partez-vous ?</label
                                  >
                                  <b-form-input
                                    id="date-time"
                                    type="datetime-local"
                                    v-model="dateDepartValue"
                                  ></b-form-input>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group mb-4">
                                  <label for="rencontre">Sièges</label>
                                  <b-form-input
                                    id="siege"
                                    type="number"
                                    name="siege"
                                    v-model="nmbrePlace"
                                    min="1"
                                  ></b-form-input>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-3">
                                <div class="form-group mb-4">
                                  <label for="depart">Point d'arrivée</label>
                                  <gmap-autocomplete
                                    type="text"
                                    class="form-control"
                                    id="depart"
                                    placeholder="Point d'arrivée"
                                    @place_changed="setArrive"
                                    :select-first-on-enter="true"
                                  >
                                  </gmap-autocomplete>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group mb-4">
                                  <label for="rencontre"
                                    >Lieu de Rencontre Arrivée</label
                                  >
                                  <gmap-autocomplete
                                    type="text"
                                    class="form-control"
                                    id="depart"
                                    placeholder="Lieu de Rencontre Arrivée"
                                    @place_changed="setRencontreArrive"
                                    :select-first-on-enter="true"
                                  >
                                  </gmap-autocomplete>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group mb-4">
                                  <label for="depart">Escales</label>
                                  <gmap-autocomplete
                                    type="text"
                                    class="form-control"
                                    id="depart"
                                    placeholder="Escales"
                                    @place_changed="setEscales"
                                    :select-first-on-enter="true"
                                  >
                                  </gmap-autocomplete>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group mb-4">
                                  <label for="">Valide</label>
                                  <multiselect
                                    v-model="valueEscales"
                                    :options="suggestEscales"
                                    label="nom"
                                    track-by="nom"
                                    :multiple="true"
                                    :close-on-select="true"
                                  ></multiselect>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </tab-content>

                  <tab-content title="">
                    <div class="tab-pane" id="billing-info">
                      <form>
                        <div>
                          <div>
                            <div class="row">
                              <div class="col-lg-3">
                                <div class="form-group mb-4">
                                  <label for="">Flexibilité</label>
                                  <b-form-input
                                    id=""
                                    type="number"
                                    v-model="flexibilite"
                                    min="5"
                                    step="5"
                                    max="15"
                                  ></b-form-input>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group mb-4">
                                  <label for="">Bagage</label>
                                  <multiselect
                                    v-model="bagageValue"
                                    :options="bagage"
                                    :multiple="false"
                                    :close-on-select="true"
                                  ></multiselect>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group mb-4">
                                  <label for="">Mode de Réservation</label>
                                  <multiselect
                                    v-model="modeValue"
                                    :options="mode"
                                    :multiple="false"
                                    :close-on-select="true"
                                  ></multiselect>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group mb-4">
                                  <label for="">Commentaire</label>
                                  <b-input type="text" v-model="infos">
                                  </b-input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </tab-content>

                  <tab-content title="">
                    <div class="tab-pane" id="shipping-info">
                      <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="notification"
                        show
                        dismissible
                        >{{ notification }}</b-alert
                      >
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div
                            class="card border rounded active shipping-address"
                          >
                            <div class="card-body">
                              <div class="mb-2">
                                <span
                                  ><i class="fa fa-map-marker mr-2"></i
                                  >Départ</span
                                >
                                <h5 class="font-size-14 float-right">
                                  {{ trajet.lieuDepart.nom }}
                                </h5>
                              </div>
                              <div class="mb-2">
                                <span
                                  ><i class="fa fa-clock mr-2"></i>Heure de
                                  Départ</span
                                >
                                <h5 class="font-size-14 float-right">
                                  {{ dateDepart.toLocaleString("fr-FR") }}
                                </h5>
                              </div>
                              <div class="mb-2">
                                <span
                                  ><i class="fa fa-map-marker mr-2"></i
                                  >Arrivée</span
                                >
                                <h5 class="font-size-14 float-right">
                                  {{ trajet.lieuArrivee.nom }}
                                </h5>
                              </div>
                              <div class="mb-2">
                                <span
                                  ><i class="fa fa-clock mr-2"></i>Heure
                                  d'Arrivée</span
                                >
                                <h5 class="font-size-14 float-right">
                                  {{ dateArrivee.toLocaleString("fr-FR") }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div
                            class="card border rounded active shipping-address"
                          >
                            <div class="card-body">
                              <div class="form-group mb-4">
                                <label for="prix">Prix</label>
                                <div
                                  style="height: 50px"
                                  class="product-cart-touchspin form-control float-right font-size-14"
                                >
                                  <NumberInputSpinner
                                    :min="`${priceget.prixMin}`"
                                    :max="`${priceget.prixRouge}`"
                                    :integerOnly="true"
                                    :value="`${priceget.prix}`"
                                    :step="50"
                                    v-model="price"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-content>
                </form-wizard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

//Chargement des cartes /*var departForm = document.getElementById("depart");
var rencontreDepartForm = document.getElementById("rencontre"); var placeDepart
= new google.maps.places.Autocomplete(departForm); var placeRencontreDepart =
new google.maps.places.Autocomplete( rencontreDepartForm );
google.maps.event.addListener(placeDepart, "place_changed", function() { var
place = placeDepart.getPlace(); if (place && place.geometry) {
this.trajet.lieuDepart.nom = place.formatted_address;
this.trajet.lieuDepart.latitude = place.geometry.location.lat();
this.trajet.lieuDepart.longitude = place.geometry.location.lng(); } });
/*google.maps.event.addListener( placeRencontreDepart, "place_changed",
function() { var place = placeRencontreDepart.getPlace(); if (place &&
place.geometry) { this.trajet.pointDeRencontre.nom = place.formatted_address;
this.trajet.pointDeRencontre.latitude = place.geometry.location.lat();
this.trajet.pointDeRencontre.longitude = place.geometry.location.lng(); } } );

<b-form-input
  id="prix"
  type="number"
  name="prix"
  min="1"
  value="`${price}`"
  :max="5"
></b-form-input>
*/
